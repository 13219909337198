import React, { useState, useEffect } from "react";
import giftsData from "../../resources/gifts.json";
import "./ConfirmationPage.css";

const ConfirmationPage = ({ responseData }) => {
    const [expandedAttribute, setExpandedAttribute] = useState(null);
    const [giftDescriptions, setGiftDescriptions] = useState({});
  // Convert the results object into an array of [attribute, score] pairs and sort by score
  const sortedResults = Object.entries(responseData.results).sort(
    (a, b) => b[1] - a[1]
  );

  // Determine the score threshold for the top attributes (including ties for third place)
  const topScoresThreshold = sortedResults[2] ? sortedResults[2][1] : null;

  // Filter the attributes to get only those with scores equal to or greater than the threshold
  const topAttributes = sortedResults.filter(
    ([attribute, score]) => score >= topScoresThreshold
  );

  //result display code
  useEffect(() => {
    // Assume giftsData is the default export of gifts.json
    // Transform the array into an object keyed by the name of the gift for easy access
    const descriptions = giftsData.reduce((acc, gift) => {
      acc[gift.name] = gift.description;
      return acc;
    }, {});
    setGiftDescriptions(descriptions);
  }, []);

  // Function to toggle the expanded attribute
  const toggleAttribute = (attribute) => {
    setExpandedAttribute(expandedAttribute === attribute ? null : attribute);
  };

  return (
    <div className="ConfirmationPage">
      <h1>Thank You for Your Submission</h1>
      <p>You have completed the quiz!</p>
      <p>We will contact you at {responseData.email} with your results soon.</p>
      {topAttributes.length > 0 && (
        <>
          <h2>Your Top Attributes</h2>
          <ul>
            {topAttributes.map(([attribute, score], index) => {
              const isExpanded = expandedAttribute === attribute;
              return (
                <li key={index} onClick={() => toggleAttribute(attribute)}>
                  {attribute}: {score}
                  {isExpanded && (
                    <p className="supportingText">
                      {giftDescriptions[attribute]}
                    </p>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default ConfirmationPage;
