// utils/formUtils.js
export const determineStartingPage = (loadedAnswers, questionsPerPage) => {
  const answeredQuestionCount = Object.keys(loadedAnswers).length;
  return Math.ceil(answeredQuestionCount / questionsPerPage);
};

export const mapAnswersToArray = (answers) => {
  return Object.keys(answers).map((questionId) => ({
    question: parseInt(questionId),
    answer: answers[questionId],
  }));
};
