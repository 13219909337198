import React, { useState, useEffect, useRef } from "react";
import Question from "./Question";
import "./QuestionForm.css";

const QuestionForm = ({
  questions,
  answers,
  handleOptionChange,
  handleSubmit,
  handleSaveProgress,
  currentPage,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const questionsPerPage = 9;
  const totalPages = Math.ceil(questions.length / questionsPerPage);

  // Ref for tracking form elements
  const formRef = useRef(null);

  // Function to check if all questions on the current page are answered
  const validateCurrentPage = () => {
    const startIndex = currentPage * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    const currentQuestions = questions.slice(startIndex, endIndex);

    let isValid = true;
    let firstUnansweredRef = null;

    const newValidationErrors = {};
    currentQuestions.forEach((question) => {
      if (!answers[question.id]) {
        isValid = false;
        newValidationErrors[question.id] = true;
        if (!firstUnansweredRef) {
          firstUnansweredRef = document.getElementById(
            `question-${question.id}`
          );
        }
      }
    });

    setValidationErrors(newValidationErrors);

    if (!isValid && firstUnansweredRef) {
      firstUnansweredRef.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    return isValid;
  };

  // Function to handle Next Page click
  const handleNextPage = async () => {
    if (validateCurrentPage()) {
      await handleSaveProgress(currentPage + 1);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateCurrentPage()) {
      await handleSaveProgress();
      handleSubmit();
    }
  };

  // Scroll on page change
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  // Calculate progress percentage
  const progressPercentage = ((currentPage + 1) / totalPages) * 100;

  // Slice questions array to show only a subset per page
  const currentQuestions = questions.slice(
    currentPage * questionsPerPage,
    (currentPage + 1) * questionsPerPage
  );

  return (
    <div className="QuestionForm" ref={formRef}>
      <h1>Quiz App</h1>
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <form onSubmit={handleFormSubmit}>
        {currentQuestions.map((question) => (
          <Question
            key={question.id}
            questionId={question.id}
            questionText={question.text}
            selectedValue={answers[question.id]}
            onChange={handleOptionChange}
            hasError={validationErrors[question.id]}
          />
        ))}
        {currentPage < totalPages - 1 && (
          <button type="button" onClick={handleNextPage}>
            Next Page
          </button>
        )}
        {currentPage === totalPages - 1 && (
          <button type="submit">Submit</button>
        )}
      </form>
    </div>
  );
};

export default QuestionForm;
