// hooks/useErrorHandling.js
import { useState } from "react";

const useErrorHandling = () => {
  const [error, setError] = useState(null);

  const handleError = (errorResponse) => {
    setError(
      errorResponse?.data?.errorMessage ||
        errorResponse?.message ||
        "An unknown error occurred"
    );
  };

  const resetError = () => {
    setError(null);
  };

  return { error, handleError, resetError };
};

export default useErrorHandling;
