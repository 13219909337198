// MultiStepForm.js
import React, { useState } from "react";
import useFormHandlers from "../hooks/UseFormHandlers";
import useErrorHandling from "../hooks/useErrorHandling";
import { determineStartingPage, mapAnswersToArray } from "../utils/FormUtils";
import apiService from "../services/ApiService";
import questions from "../resources/questions.json";

import WelcomePage from "./pages/WelcomePage";
import QuestionForm from "./pages/QuestionForm";
import ConfirmationPage from "./pages/ConfirmationPage";
import { TailSpin } from "react-loader-spinner";
import "./MultiStepForm.css";

// Assuming initialFormData is defined elsewhere or you can define it here
const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  answers: {},
};

const MultiStepForm = () => {
  // state
  const [step, setStep] = useState(1);
  const [responseData, setResponseData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  //hooks
  const { formData, setFormData, handleChange, handleQuestionChange } = useFormHandlers(initialFormData);
  const { error, handleError, resetError } = useErrorHandling();

  const nextStep = () => setStep((prevStep) => prevStep + 1);

  const checkEmailAndProceed = async (email) => {
    setLoading(true);
    resetError();

    try {
      const response = await apiService.checkProgress(email);

      if (response.data.status === "completed") {
        setResponseData(response.data);
        setStep(3);
      } else if (response.data.status === "in-progress") {
        setFormData({ ...formData, answers: response.data.answers });
        setCurrentPage(determineStartingPage(response.data.answers, 9));
        setStep(2);
      } else {
        nextStep();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveProgress = async (newCurrentPage) => {
    setLoading(true);
    resetError();

    const formattedAnswers = mapAnswersToArray(formData.answers)

    try {
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        answers: formattedAnswers,
      };

      const response = await apiService.saveProgress(payload);

      if (response.status === 200 && response.data.status === "in-progress") {
        setCurrentPage(newCurrentPage);
      } else {
        handleError(new Error("Failed to save progress."));
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    resetError();

    try {
      const fullData = {
        ...formData,
        answers: mapAnswersToArray(formData.answers),
      };

      const response = await apiService.submitQuiz(fullData);

      if (response.status === 200) {
        setResponseData(response.data);
        nextStep();
      } else {
        handleError(new Error(response.statusText));
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <TailSpin
          color="#00BFFF"
          height={80}
          width={80}
          ariaLabel="loading-indicator"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        <h2>An Error Occurred</h2>
        <p>{error}</p>
      </div>
    );
  }

  switch (step) {
    case 1:
      return (
        <WelcomePage
          formData={formData}
          handleChange={handleChange}
          setFormData={setFormData}
          nextStep={nextStep}
          checkEmailAndProceed={checkEmailAndProceed}
        />
      );
    case 2:
      return (
        <QuestionForm
          questions={questions}
          answers={formData.answers}
          currentPage={currentPage}
          handleOptionChange={handleQuestionChange}
          handleSubmit={handleSubmit}
          handleSaveProgress={handleSaveProgress}
        />
      );
    case 3:
      return (
        <ConfirmationPage formData={formData} responseData={responseData} />
      );
    default:
      return <div>Unknown step</div>;
  }
};

export default MultiStepForm;
