// services/apiService.js
import axios from "axios";

const BASE_URL = "https://testapi.pfs-web-dev.com";

const apiService = {
  checkProgress: (email) => axios.post(`${BASE_URL}/progress`, { email }),
  saveProgress: (payload) => axios.put(`${BASE_URL}/update`, payload),
  submitQuiz: (fullData) => axios.post(`${BASE_URL}/submit`, fullData),
};

export default apiService;
