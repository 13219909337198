// useFormHandlers.js
import { useState } from "react";

const useFormHandlers = (initialFormData) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (input) => (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [input]: e.target.value,
    }));
  };

  const handleQuestionChange = (questionId, value) => {
    setFormData({
      ...formData,
      answers: {
        ...formData.answers,
        [questionId]: value, // Ensure this is the numeric value
      },
    });
  };

  return { formData, setFormData, handleChange, handleQuestionChange };
};

export default useFormHandlers;
