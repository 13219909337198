import './Question.css';

const Question = ({ questionId, questionText, selectedValue, onChange, hasError }) => {
  const questionClass = `Question ${hasError ? 'error' : ''}`;
    // Assuming options are the same for all questions and passed in as a prop
    const options = ["Almost Never", "Sometimes", "Almost Always"];
      const optionValues = {
        "Almost Never": 1,
        "Sometimes": 2,
        "Almost Always": 3,
      };

const boxChecked = (selectedValue, option) => {
  return selectedValue === optionValues[option];
};
    
    return (
      <div className={questionClass} id={`question-${questionId}`}>
        <label>
          {questionId}. {questionText}
        </label>
        <div>
          {options.map((option, index) => (
            <label key={index}>
              <input
                type="radio"
                name={`question-${questionId}`}
                value={optionValues[option]}
                checked={boxChecked(selectedValue, option)}
                onChange={() => onChange(questionId, optionValues[option])}
                className="radio-button"
              />
              {option}
            </label>
          ))}
        </div>
      </div>
    );
  };

  export default Question;
