import React, { useState } from "react";
import "./WelcomePage.css";

const WelcomePage = ({ formData, setFormData, checkEmailAndProceed }) => {
  const [errors, setErrors] = useState({});

  const isValidEmail = (email) => {
    // Simple regex for basic email validation
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = () => {
    let tempErrors = {};
    let isValid = true;

    // Check for first name
    if (!formData.firstName) {
      isValid = false;
      tempErrors["firstName"] = "First Name is required";
    }

    // Check for last name
    if (!formData.lastName) {
      isValid = false;
      tempErrors["lastName"] = "Last Name is required";
    }

    // Check for email
    if (!formData.email) {
      isValid = false;
      tempErrors["email"] = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      isValid = false;
      tempErrors["email"] = "Invalid email format";
    }

    setErrors(tempErrors);

    // If all validations pass, then proceed to the next step
    if (isValid) {
      //if form data is valid check if user exists
      checkEmailAndProceed(formData.email);
    }
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
    // Optionally clear errors
    setErrors({ ...errors, [input]: null });
  };

  return (
    <div className="WelcomeForm">
      <h1>Welcome to the Quiz</h1>
      <p>Please enter your details to start the quiz.</p>
      <input
        type="text"
        placeholder="First Name"
        value={formData.firstName}
        onChange={handleChange("firstName")}
        className={errors.firstName ? "error" : ""}
      />
      {errors.firstName && <p className="error">{errors.firstName}</p>}
      <input
        type="text"
        placeholder="Last Name"
        value={formData.lastName}
        onChange={handleChange("lastName")}
        className={errors.lastName ? "error" : ""}
      />
      {errors.lastName && <p className="error">{errors.lastName}</p>}
      <input
        type="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange("email")}
        className={errors.email ? "error" : ""}
      />
      {errors.email && <p className="error">{errors.email}</p>}
      <button onClick={handleSubmit}>Next</button>
    </div>
  );
};

export default WelcomePage;
